<template>
  <div class="container-fluid" v-if="!loading">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs">
        <billing-nav :user="user" :path="path"></billing-nav>
      </div>
      <div class="col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-body" v-if="loadedPaymentMethods">
            <div class="row mb-2">
              <div class="col">
                <h4>Payment Methods</h4>
              </div>
            </div>
            <div class="row mb-3" v-if="clinicPaymentMethods.length === 0 && !showAddCard">
              <div class="col-6 mx-auto">
                <div class="card bg-light shadow-sm border-0">
                  <div class="card-body text-center">
                    <i class="far fa-info-circle fa-4x text-primary"></i>
                    <p>No Payment Methods found.</p>
                    <div class="row mb-3" v-if="!showAddCard">
                      <div class="col">
                        <div class="card cursor-pointer" @click="showAddCard=true">
                          <div class="card-body rounded text-center border-1 border-primary"
                               :class="showAddCard ? 'bg-primary text-white' : 'bg-light text-primary'">
                            <h5><i class="far fa-credit-card me-2 "></i><small>Add New Card</small></h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3" v-for="method in clinicPaymentMethods">
              <div class="col-6">
                <div class="card">
                  <div class="card-body rounded text-center bg-light">
                    <h5>{{ method.cardholder_name }}</h5>
                    <h5><small>**** **** **** {{ method.card_last_four }}</small></h5>
                    <h5><small>Expires: {{ method.exp_month | padMonth }}/{{ method.exp_year }}</small></h5><br />
                    <span class="text-uppercase" style="position: absolute; bottom: 5px; left: 10px;">
                      <i class="far fa-credit-card me-2 text-primary"></i>{{ method.card_brand }}
                    </span>
                    <span style="position: absolute; bottom: 5px; right: 10px;">
                      <button class="btn btn-primary btn-sm me-2" v-if="method.default">Default</button>
                      <button class="btn btn-outline-primary btn-sm me-2" v-else @click="setDefault(method)">Default</button>
                      <button class="btn btn-sm btn-outline-danger" @click="deleteCard(method)">
                        <i class="far fa-trash"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3" v-if="!showAddCard && clinicPaymentMethods.length > 0">
              <div class="col-6">
                <div class="card cursor-pointer" @click="showAddCard=true">
                  <div class="card-body rounded text-center border-1 border-primary"
                        :class="showAddCard ? 'bg-primary text-white' : 'bg-light text-primary'">
                    <h5><i class="far fa-credit-card me-2 "></i><small>Add New Card</small></h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3" :class="!showAddCard ? 'd-none' : ''">
              <div class="col-6" :class="clinicPaymentMethods.length === 0 ? 'mx-auto' : ''">
                <div class="card border-0 mb-3">
                  <div class="card-body" v-if="loading">
                    <span class="h4" >Loading...</span>
                  </div>
                  <div class="card-body bg-light rounded" :class="loading ? 'd-none' : ''">
                    <form role="form">
                      <!-- Cardholder's Name -->
                      <div class="row mb-2">
                        <div class="col mx-auto">
                          <label for="name">Cardholder's Name</label>
                          <input type="text" class="form-control" name="name" v-model="name" />
                        </div>
                      </div>

                      <!-- Card Details -->
                      <div class="row mb-1">
                        <div class="col mx-auto">
                          <label for="name">Card Details</label>
                          <div id="payment-card-element"></div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="float-end">
                  <button class="btn btn-light me-2" @click="showAddCard=false">
                    <i class="far fa-times-circle me-1"></i>
                    Cancel
                  </button>
                  <button class="btn btn-primary" @click="saveCard">
                    <i class="far fa-credit-card me-1"></i>
                    Add Card
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BillingNav from "../partials/BillingNav";
export default {
  props: [],
  data() {
    return {
      showAddCard: false,
      loading: true,
      busy: false,
      cardElement: null,
      stripe_key: null,
      stripe: null,
      name: '',
      loadedPaymentMethods: false,
      clinicPaymentMethods: null,
    }
  },
  components: {
    BillingNav
  },
  methods: {
    saveCard(){
      this.stripe.createPaymentMethod(
              {
                type: 'card',
                card: this.cardElement,
                billing_details: {
                  name: this.name
                }
              })
              .then(result => {
                if (result.error) {
                  self.hasCardErrors = true;
                  return;
                }
                this.$axios.post(process.env.VUE_APP_API_URL + "/billing/add-payment-method", {
                  name_on_card: this.name,
                  newPaymentMethod: result.paymentMethod
                }).then(({ data }) => {
                  this.$EventBus.$emit("refreshUser");
                  this.$EventBus.$emit("alert", data);
                  setTimeout(() => {
                    window.location = "/billing/payment-methods";
                  }, 2000);
                });
              });
    },
    createCardElement(container) {
      if (!this.stripe) {
        throw "Invalid Stripe Key/Secret";
      }

      var card = this.stripe.elements().create("card", {
        // hideIcon: true,
        // hidePostalCode: true,
        style: {
          base: {
            "::placeholder": {
              color: "#aab7c4"
            },
            fontFamily:
                    'Whitney, Lato, -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"',
            color: "#495057",
            fontSize: "15px"
          }
        }
      });

      card.mount(container);
      this.loading = false;
      return card;
    },
    deleteCard(method){
      if (method.default){
        alert("Cannot remove the default payment method.");
      } else {
        var confirmed = confirm("Are you sure you wish to delete this payment method?");
        if (confirmed) {
          this.$axios.delete(process.env.VUE_APP_API_URL + "/billing/payment-methods/" + method.id).then(({ data }) => {
            this.$EventBus.$emit("refreshUser");
            this.$EventBus.$emit("alert", data);
            setTimeout(() => {
              window.location = "/billing/payment-methods";
            }, 3000);
          });
        }
      }
    },
    setDefault(method){
      var confirmed = confirm(
              "Are you sure you wish to set this as your default method? Your subscription will be updated to use this payment."
      );
      if (confirmed) {
        this.$axios.post(process.env.VUE_APP_API_URL + "/billing/set-default-payment-method", {
          billing_payment_method_id: method.id,
        }).then(({ data }) => {
          this.$EventBus.$emit("refreshUser");
          this.$EventBus.$emit("alert", data);
          setTimeout(() => {
            window.location = "/billing/payment-methods";
          }, 1000);
        });
      }
    },
    fetchStripePublicKey() {
      return this.$axios.get(process.env.VUE_APP_API_URL + "/stripe-pk")
          .then(({ data }) => {
            this.stripe_key = data;
            this.stripe = Stripe(this.stripe_key);
            this.loading = false;
          });
    },
    fetchClinicPaymentMethods() {
      return this.$axios.get(process.env.VUE_APP_API_URL + "/billing/fetch-payment-methods")
          .then(({ data }) => {
            this.clinicPaymentMethods = data;
            this.loadedPaymentMethods = true;
          });
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
  mounted() {
    this.fetchClinicPaymentMethods().then(() => {
      this.fetchStripePublicKey().then(() => {
        this.cardElement = this.createCardElement("#payment-card-element");
      });
    });
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    padMonth(month) {
      if (parseInt(month) < 10){
        return "0" + month;
      } else {
        return month;
      }
    }
  },
};
</script>

<style>
</style>
